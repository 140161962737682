<template>
  <div id="app">
    <div v-if="loading">
      正在等待渲染，请稍后
      <p>如长时间无法渲染，请刷新页面或返回重新打开</p>
    </div>
    <button
      v-if="!loading && printData && printData.id"
      @click="sendBase64Img()"
    >
      下载图片
    </button>
    <div id="printView" />
  </div>
</template>

<script>
import axios from "axios";
import html2canvas from "html2canvas";
import { template1, template2 } from "./assets/template";
import { hiprint } from "vue-plugin-hiprint";
import $ from "jquery";
export default {
  name: "App",
  data() {
    return {
      loading: false,
      test: "",
      printData: null,
      hiprintTemplate: null,
      template1: template1,
      template2: template2,
      cacheId: "",
      headers: "",
      params: ""
    };
  },
  mounted() {
    this.checkLoad();
  },
  methods: {
    sendBase64Img() {
      if (this.loading) return;
      this.loading = true;
      var view = document.getElementById("printView");
      html2canvas(view).then((canvas) => {
        // 转成图片，生成图片地址
        var imgBase64 = canvas.toDataURL("image/png");
        window.uni.postMessage({
          data: {
            action: "printView",
            data: imgBase64,
          },
        });
        window.uni.navigateBack({
          delta: 1, //返回层数，2则上上页
        });
      });
    },
    checkLoad() {
      this.cacheId = this.getUrlParam("cacheId");
      this.headers = this.getUrlParam("headers");
      if (this.cacheId && this.headers) {
        axios({
          method: 'get',
          headers: JSON.parse(this.headers),
          url: 'https://xf.addeasy.com.cn/web/template/getCache',
          params: {
            cacheId: this.cacheId
          }
        }).then(res => {
          if (res.data.code === 200 && res.data.msg && res.data.msg !== "") {
              this.params = res.data.msg
              this.init();
              this.loadData();
            }
        })
      }
    },
    getUrlParam(name) {
      //构造一个含有目标参数的正则表达式对象
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      //匹配目标参数
      var r = window.location.search.substr(1).match(reg);
      //返回参数值
      if (r != null) {
        return decodeURI(r[2]);
      }
      return null;
    },
    init() {
      hiprint.init();
    },
    loadData() {
      this.params = JSON.parse(this.params);
      this.printData = JSON.parse(this.params.cache).printData
      var template = this.printData.template;
      var style = template.style;
      this.updateBusinessList();
      this.setCustomer();
      switch (style) {
        case 0:
          this.hiprintTemplate = new hiprint.PrintTemplate({
            template: this.template1,
          });
          this.printData.title = template.lookUp;
          this.printData.phone = template.contact;
          this.printData.wechat = template.wechat;
          this.printData.address = template.address;
          this.printData.wechatCode = template.qrCode;
          this.printData.service = template.afterSales;
          this.showPrintView();
          break;
        case 1:
          this.hiprintTemplate = new hiprint.PrintTemplate({
            template: this.template2,
          });
          var params = JSON.parse(template.remark);
          this.printData.title = template.lookUp;
          this.printData.phone = template.contact;
          this.printData.wechat = template.wechat;
          this.printData.address = template.address;
          this.printData.service = template.afterSales;
          this.printData.accountName = params.accountName;
          this.printData.bank = params.bank;
          this.printData.bankAccount = params.bankAccount;
          this.showPrintView();
          break;
      }
    },
    setCustomer() {
      this.printData.orderId = this.printData.orderNo;
      this.printData.createName = this.printData.createBy;
      this.printData.clientName = this.printData.customer.name;
      this.printData.clientPhone = this.printData.customer.tel;
      this.printData.clientBrand = this.printData.customer.brand;
      this.printData.createTime = this.parseTime(
        this.printData.createTime,
        "{y}-{m}-{d}"
      );
    },
    updateBusinessList() {
      this.printData.countTotal = 0;
      var businessList = this.printData.businessList;
      for (var i = 0; i < businessList.length; i++) {
        //更新总价
        businessList[i].totalPrice = this.numFilter(
          Number(businessList[i].num) * Number(businessList[i].price)
        );
        this.printData.countTotal = this.numFilter(
          Number(this.printData.countTotal) + Number(businessList[i].totalPrice)
        );
      }
      this.printData.table = businessList;
      this.printData.totalChina = this.toChies(this.printData.countTotal);
    },
    showPrintView() {
      $("#printView").html(this.hiprintTemplate.getHtml(this.printData));
    },
    numFilter(value) {
      return parseFloat(value).toFixed(2);
    },
    parseTime(time, pattern) {
      if (arguments.length === 0 || !time) {
        return null;
      }
      const format = pattern || "{y}-{m}-{d} {h}:{i}:{s}";
      let date;
      if (typeof time === "object") {
        date = time;
      } else {
        if (typeof time === "string" && /^[0-9]+$/.test(time)) {
          time = parseInt(time);
        } else if (typeof time === "string") {
          time = time
            .replace(new RegExp(/-/gm), "/")
            .replace("T", " ")
            .replace(new RegExp(/\.[\d]{3}/gm), "");
        }
        if (typeof time === "number" && time.toString().length === 10) {
          time = time * 1000;
        }
        date = new Date(time);
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay(),
      };
      const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key];
        // Note: getDay() returns 0 on Sunday
        if (key === "a") {
          return ["日", "一", "二", "三", "四", "五", "六"][value];
        }
        if (result.length > 0 && value < 10) {
          value = "0" + value;
        }
        return value || 0;
      });
      return time_str;
    },
    toChies(amount) {
      const cnNums = [
        "零",
        "壹",
        "贰",
        "叁",
        "肆",
        "伍",
        "陆",
        "柒",
        "捌",
        "玖",
      ];
      const cnIntRadice = ["", "拾", "佰", "仟"];
      const cnIntUnits = ["", "万", "亿", "兆"];
      const cnDecUnits = ["角", "分"];
      const cnInteger = "整";
      const cnIntLast = "元";
      const maxNum = 9999999999999999.99;
      let integerNum;
      let decimalNum;
      let chineseStr = "";
      let parts;
      if (amount === "") {
        return "";
      }
      amount = parseFloat(amount);
      if (amount >= maxNum) {
        return "";
      }
      if (amount === 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
      }
      amount = amount.toString();
      if (amount.indexOf(".") === -1) {
        integerNum = amount;

        decimalNum = "";
      } else {
        parts = amount.split(".");
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
      }
      if (parseInt(integerNum, 10) > 0) {
        let zeroCount = 0;
        const IntLen = integerNum.length;
        for (let i = 0; i < IntLen; i++) {
          const n = integerNum.substr(i, 1);
          const p = IntLen - i - 1;
          const q = p / 4;
          const m = p % 4;
          if (n === "0") {
            zeroCount++;
          } else {
            if (zeroCount > 0) {
              chineseStr += cnNums[0];
            }
            zeroCount = 0;
            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
          }
          if (m === 0 && zeroCount < 4) {
            chineseStr += cnIntUnits[q];
          }
        }
        chineseStr += cnIntLast;
      }
      if (decimalNum !== "") {
        const decLen = decimalNum.length;
        for (let i = 0; i < decLen; i++) {
          const n = decimalNum.substr(i, 1);
          if (n !== "0") {
            chineseStr += cnNums[Number(n)] + cnDecUnits[i];
          }
        }
      }
      if (chineseStr === "") {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
      } else if (decimalNum === "") {
        chineseStr += cnInteger;
      }
      return chineseStr;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>