export const template1 = {
    "panels": [
      {
        "index": 0,
        "name": 1,
        "paperType": "A5",
        "height": 148,
        "width": 210,
        "paperHeader": 109.5,
        "paperFooter": 255,
        "printElements": [
          {
            "options": {
              "left": 55.5,
              "top": 24,
              "height": 9.75,
              "width": 483,
              "field": "title",
              "testData": "广东雄风知识产权有限公司分公司（收据）",
              "fontSize": 21,
              "fontWeight": "600",
              "textAlign": "center",
              "textContentVerticalAlign": "middle",
              "lineHeight": 18
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 55.5,
              "top": 46.5,
              "height": 9,
              "width": 483
            },
            "printElementType": {
              "type": "hline"
            }
          },
          {
            "options": {
              "left": 508.5,
              "top": 49,
              "height": 15,
              "width": 43.5,
              "title": "（同微）",
              "fontSize": 10.5,
              "textAlign": "center",
              "textContentVerticalAlign": "middle"
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 49.5,
              "top": 49.5,
              "height": 15,
              "width": 33,
              "title": "地址：",
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle"
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 81,
              "top": 49.5,
              "height": 15,
              "width": 226.5,
              "fontSize": 9.75,
              "textContentVerticalAlign": "middle",
              "field": "address",
              "testData": "广东省普宁市池尾上寮村委对面2楼雄风知识产权",
              "right": 315,
              "bottom": 61.5,
              "vCenter": 202.5,
              "hCenter": 54,
              "coordinateSync": false,
              "widthHeightSync": false,
              "qrCodeLevel": 0
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 307.5,
              "top": 49.5,
              "height": 15,
              "width": 54,
              "title": "联系电话：",
              "fontSize": 10.5,
              "textAlign": "center",
              "textContentVerticalAlign": "middle",
              "right": 364.75,
              "bottom": 64.5,
              "vCenter": 337.25,
              "hCenter": 57
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 361.5,
              "top": 49.5,
              "height": 15,
              "width": 73.5,
              "fontSize": 9.75,
              "textContentVerticalAlign": "middle",
              "field": "phone",
              "testData": "0663-2842199",
              "coordinateSync": false,
              "widthHeightSync": false,
              "textAlign": "center",
              "qrCodeLevel": 0
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 435,
              "top": 49.5,
              "height": 15,
              "width": 73.5,
              "textContentVerticalAlign": "middle",
              "field": "wechat",
              "testData": "13823766669",
              "fontSize": 9.75,
              "textAlign": "center",
              "coordinateSync": false,
              "widthHeightSync": false,
              "qrCodeLevel": 0
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 49.5,
              "top": 69,
              "height": 15,
              "width": 58.5,
              "title": "客户名称：",
              "color": "#000000",
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "right": 102,
              "bottom": 84,
              "vCenter": 75,
              "hCenter": 76.5,
              "coordinateSync": false,
              "widthHeightSync": false,
              "textAlign": "justify",
              "qrCodeLevel": 0
            },
            "printElementType": {
              "title": "文本",
              "type": "text"
            }
          },
          {
            "options": {
              "left": 108,
              "top": 69,
              "height": 15,
              "width": 199.5,
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "field": "clientName",
              "testData": "秦敏旭",
              "right": 292.5,
              "bottom": 84,
              "vCenter": 198,
              "hCenter": 76.5
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 307.5,
              "top": 69,
              "height": 15,
              "width": 54,
              "title": "客户电话：",
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "right": 360.75,
              "bottom": 84,
              "vCenter": 333.75,
              "hCenter": 76.5,
              "coordinateSync": false,
              "widthHeightSync": false,
              "textAlign": "justify",
              "qrCodeLevel": 0
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 361.5,
              "top": 69,
              "height": 15,
              "width": 73.5,
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "field": "clientPhone",
              "testData": "18200911665",
              "right": 434.25,
              "bottom": 83.25,
              "vCenter": 397.5,
              "hCenter": 75.75,
              "coordinateSync": false,
              "widthHeightSync": false,
              "textAlign": "center",
              "qrCodeLevel": 0
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 435,
              "top": 69,
              "height": 15,
              "width": 40,
              "title": "日期：",
              "textAlign": "right",
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "right": 485.25,
              "bottom": 84,
              "vCenter": 465,
              "hCenter": 76.5
            },
            "printElementType": {
              "title": "文本",
              "type": "text"
            }
          },
          {
            "options": {
              "left": 474,
              "top": 69,
              "height": 15,
              "width": 70,
              "field": "createTime",
              "testData": "2020-06-19",
              "fontSize": 10.5,
              "textAlign": "right",
              "textContentVerticalAlign": "middle"
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 49.5,
              "top": 88.5,
              "height": 15,
              "width": 58.5,
              "title": "公司/品牌：",
              "color": "#000000",
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "right": 101.49375915527344,
              "bottom": 103.74375915527344,
              "vCenter": 74.49375915527344,
              "hCenter": 96.24375915527344,
              "coordinateSync": false,
              "widthHeightSync": false,
              "textAlign": "justify",
              "qrCodeLevel": 0
            },
            "printElementType": {
              "title": "文本",
              "type": "text"
            }
          },
          {
            "options": {
              "left": 108,
              "top": 88.5,
              "height": 15,
              "width": 199.5,
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "field": "clientBrand",
              "testData": "揭阳市加易网络科技有限责任公司",
              "right": 308.25,
              "bottom": 103.5,
              "vCenter": 208.5,
              "hCenter": 96,
              "coordinateSync": false,
              "widthHeightSync": false,
              "qrCodeLevel": 0
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 307.5,
              "top": 88.5,
              "height": 15,
              "width": 54,
              "title": "制单人：",
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "right": 391.5,
              "bottom": 102.75,
              "vCenter": 369.75,
              "hCenter": 95.25,
              "coordinateSync": false,
              "widthHeightSync": false,
              "textAlign": "justify",
              "qrCodeLevel": 0
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 361.5,
              "top": 88.5,
              "height": 15,
              "width": 73.5,
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "field": "createName",
              "testData": "江琼",
              "coordinateSync": false,
              "widthHeightSync": false,
              "textAlign": "center",
              "qrCodeLevel": 0
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 435,
              "top": 88.5,
              "height": 15,
              "width": 40,
              "title": "单号：",
              "textAlign": "right",
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle"
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 474,
              "top": 88.5,
              "height": 15,
              "width": 70,
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "field": "orderId",
              "textAlign": "right",
              "testData": "2020-06-19"
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 36,
              "top": 255,
              "height": 19.5,
              "width": 9
            },
            "printElementType": {
              "type": "vline"
            }
          },
          {
            "options": {
              "left": 36,
              "top": 255,
              "height": 9,
              "width": 525
            },
            "printElementType": {
              "type": "hline"
            }
          },
          {
            "options": {
              "left": 36,
              "top": 255,
              "height": 19.5,
              "width": 99,
              "title": "金额合计<大写>：",
              "fontSize": 10.5,
              "textAlign": "center",
              "textContentVerticalAlign": "middle"
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 135,
              "top": 255,
              "height": 19.5,
              "width": 216,
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "field": "totalChina",
              "testData": "陆仟元整"
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 36,
              "top": 110,
              "height": 145.5,
              "width": 525,
              "tableFooterRepeat": "page",
              "autoCompletion": true,
              "field": "table",
              "textAlign": "center",
              "fontSize": 11.25,
              "tableHeaderFontSize": 11.25,
              "tableBodyRowHeight": 18,
              "columns": [
                [{
                  "title": "业务类型",
                  "field": "name",
                  "width": 189.3326949860724,
                  "colspan": 1,
                  "rowspan": 1,
                  "checked": true,
                  "columnId": "name"
                }, {
                  "title": "数量",
                  "field": "num",
                  "width": 56.25250696378833,
                  "colspan": 1,
                  "rowspan": 1,
                  "checked": true,
                  "columnId": "num"
                }, {
                  "title": "单价",
                  "field": "price",
                  "width": 69.4147980501393,
                  "align": "center",
                  "colspan": 1,
                  "rowspan": 1,
                  "checked": true,
                  "columnId": "price"
                }, {
                  "title": "金额",
                  "field": "totalPrice",
                  "width": 79.40807799442896,
                  "colspan": 1,
                  "rowspan": 1,
                  "checked": true,
                  "columnId": "totalPrice"
                }, {
                  "title": "备注",
                  "field": "remark",
                  "width": 130.59192200557104,
                  "colspan": 1,
                  "rowspan": 1,
                  "checked": true,
                  "columnId": "remark"
                }]
              ]
            },
            "printElementType": {
              "title": "表格",
              "type": "table",
              "footerFormatter": function (options, rows, data, currentPageGridRowsData) {
                var total = 0
                var count = 0
                for (var i = 0; i < currentPageGridRowsData.length; i++) {
                  count = Number(currentPageGridRowsData[i].num) + count
                  // var price = parseFloat(currentPageGridRowsData[i].price).toFixed(2) * currentPageGridRowsData[i].num
                  // total = total + price
                  total = parseFloat(Number(currentPageGridRowsData[i].totalPrice) + Number(total)).toFixed(2)
                }
                return "<tr><td>合计</td><td>" + count + "</td><td></td><td>" + total + "</td><td></td></tr>"
              }
            }
          },
          {
            "options": {
              "left": 351,
              "top": 255,
              "height": 19.5,
              "width": 48,
              "title": "￥：",
              "letterSpacing": 10.5,
              "textAlign": "center",
              "textContentVerticalAlign": "middle"
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 399,
              "top": 255,
              "height": 19.5,
              "width": 162,
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "field": "countTotal",
              "testData": "6000"
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 560.4,
              "top": 255,
              "height": 20,
              "width": 9
            },
            "printElementType": {
              "type": "vline"
            }
          },
          {
            "options": {
              "left": 36,
              "top": 274.5,
              "height": 9,
              "width": 525
            },
            "printElementType": {
              "type": "hline"
            }
          },
          {
            "options": {
              "left": 36,
              "top": 282,
              "height": 82.5,
              "width": 12,
              "title": "售后服务微信",
              "fontSize": 10.5,
              "lineHeight": 13.5
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 54,
              "top": 282,
              "height": 82.5,
              "width": 84,
              "textType": "qrcode",
              "field": "wechatCode",
              "testData": "031001800204"
            },
            "printElementType": {
              "title": "文本",
              "type": "text"
            }
          },
          {
            "options": {
              "left": 154.5,
              "top": 313.5,
              "height": 18,
              "width": 75,
              "title": "售后服务号码：",
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle"
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 229.5,
              "top": 313.5,
              "height": 18,
              "width": 69,
              "field": "service",
              "testData": "13724322226",
              "fontSize": 10.5,
              "textAlign": "center",
              "textContentVerticalAlign": "middle"
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 297,
              "top": 313.5,
              "height": 18,
              "width": 46.5,
              "title": "（同微）",
              "fontSize": 10.5,
              "textAlign": "center",
              "textContentVerticalAlign": "middle"
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 352.5,
              "top": 313.5,
              "height": 18,
              "width": 114,
              "title": "收款单位（盖章）：",
              "color": "#cc5a5a",
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle"
            },
            "printElementType": {
              "title": "文本",
              "type": "text"
            }
          }
        ],
        "paperNumberLeft": 555,
        "paperNumberTop": 385,
        "paperNumberContinue": true,
        "backgroundColor": "#f1ebff",
        "overPrintOptions": {},
        "watermarkOptions": {}
      }
    ]
  }
  export const template2 = {
    "panels": [
      {
        "index": 0,
        "name": 1,
        "paperType": "A5",
        "height": 148,
        "width": 210,
        "paperHeader": 109.5,
        "paperFooter": 255,
        "printElements": [
          {
            "options": {
              "left": 55.5,
              "top": 24,
              "height": 9.75,
              "width": 483,
              "field": "title",
              "testData": "广东雄风知识产权有限公司分公司（收据）",
              "fontSize": 21,
              "fontWeight": "600",
              "textAlign": "center",
              "textContentVerticalAlign": "middle",
              "lineHeight": 18
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 55.5,
              "top": 46.5,
              "height": 9,
              "width": 483
            },
            "printElementType": {
              "type": "hline"
            }
          },
          {
            "options": {
              "left": 508.5,
              "top": 49,
              "height": 15,
              "width": 43.5,
              "title": "（同微）",
              "fontSize": 10.5,
              "textAlign": "center",
              "textContentVerticalAlign": "middle"
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 49.5,
              "top": 49.5,
              "height": 15,
              "width": 33,
              "title": "地址：",
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle"
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 81,
              "top": 49.5,
              "height": 15,
              "width": 226.5,
              "fontSize": 9.75,
              "textContentVerticalAlign": "middle",
              "field": "address",
              "testData": "广东省普宁市池尾上寮村委对面2楼雄风知识产权",
              "right": 315,
              "bottom": 61.5,
              "vCenter": 202.5,
              "hCenter": 54,
              "coordinateSync": false,
              "widthHeightSync": false,
              "qrCodeLevel": 0
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 307.5,
              "top": 49.5,
              "height": 15,
              "width": 54,
              "title": "联系电话：",
              "fontSize": 10.5,
              "textAlign": "center",
              "textContentVerticalAlign": "middle",
              "right": 364.75,
              "bottom": 64.5,
              "vCenter": 337.25,
              "hCenter": 57
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 361.5,
              "top": 49.5,
              "height": 15,
              "width": 73.5,
              "fontSize": 9.75,
              "textContentVerticalAlign": "middle",
              "field": "phone",
              "testData": "0663-2842199",
              "coordinateSync": false,
              "widthHeightSync": false,
              "textAlign": "center",
              "qrCodeLevel": 0
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 435,
              "top": 49.5,
              "height": 15,
              "width": 73.5,
              "textContentVerticalAlign": "middle",
              "field": "wechat",
              "testData": "13823766669",
              "fontSize": 9.75,
              "textAlign": "center",
              "coordinateSync": false,
              "widthHeightSync": false,
              "qrCodeLevel": 0
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 49.5,
              "top": 69,
              "height": 15,
              "width": 58.5,
              "title": "客户名称：",
              "color": "#000000",
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "right": 102,
              "bottom": 84,
              "vCenter": 75,
              "hCenter": 76.5,
              "coordinateSync": false,
              "widthHeightSync": false,
              "textAlign": "justify",
              "qrCodeLevel": 0
            },
            "printElementType": {
              "title": "文本",
              "type": "text"
            }
          },
          {
            "options": {
              "left": 108,
              "top": 69,
              "height": 15,
              "width": 199.5,
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "field": "clientName",
              "testData": "秦敏旭",
              "right": 292.5,
              "bottom": 84,
              "vCenter": 198,
              "hCenter": 76.5
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 307.5,
              "top": 69,
              "height": 15,
              "width": 54,
              "title": "客户电话：",
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "right": 360.75,
              "bottom": 84,
              "vCenter": 333.75,
              "hCenter": 76.5,
              "coordinateSync": false,
              "widthHeightSync": false,
              "textAlign": "justify",
              "qrCodeLevel": 0
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 361.5,
              "top": 69,
              "height": 15,
              "width": 73.5,
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "field": "clientPhone",
              "testData": "18200911665",
              "right": 434.25,
              "bottom": 83.25,
              "vCenter": 397.5,
              "hCenter": 75.75,
              "coordinateSync": false,
              "widthHeightSync": false,
              "textAlign": "center",
              "qrCodeLevel": 0
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 435,
              "top": 69,
              "height": 15,
              "width": 40,
              "title": "日期：",
              "textAlign": "right",
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "right": 485.25,
              "bottom": 84,
              "vCenter": 465,
              "hCenter": 76.5
            },
            "printElementType": {
              "title": "文本",
              "type": "text"
            }
          },
          {
            "options": {
              "left": 474,
              "top": 69,
              "height": 15,
              "width": 70,
              "field": "createTime",
              "testData": "2020-06-19",
              "fontSize": 10.5,
              "textAlign": "right",
              "textContentVerticalAlign": "middle"
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 49.5,
              "top": 88.5,
              "height": 15,
              "width": 58.5,
              "title": "公司/品牌：",
              "color": "#000000",
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "right": 101.49375915527344,
              "bottom": 103.74375915527344,
              "vCenter": 74.49375915527344,
              "hCenter": 96.24375915527344,
              "coordinateSync": false,
              "widthHeightSync": false,
              "textAlign": "justify",
              "qrCodeLevel": 0
            },
            "printElementType": {
              "title": "文本",
              "type": "text"
            }
          },
          {
            "options": {
              "left": 108,
              "top": 88.5,
              "height": 15,
              "width": 199.5,
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "field": "clientBrand",
              "testData": "揭阳市加易网络科技有限责任公司",
              "right": 308.25,
              "bottom": 103.5,
              "vCenter": 208.5,
              "hCenter": 96,
              "coordinateSync": false,
              "widthHeightSync": false,
              "qrCodeLevel": 0
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 307.5,
              "top": 88.5,
              "height": 15,
              "width": 54,
              "title": "制单人：",
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "right": 391.5,
              "bottom": 102.75,
              "vCenter": 369.75,
              "hCenter": 95.25,
              "coordinateSync": false,
              "widthHeightSync": false,
              "textAlign": "justify",
              "qrCodeLevel": 0
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 361.5,
              "top": 88.5,
              "height": 15,
              "width": 73.5,
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "field": "createName",
              "testData": "江琼",
              "coordinateSync": false,
              "widthHeightSync": false,
              "textAlign": "center",
              "qrCodeLevel": 0
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 435,
              "top": 88.5,
              "height": 15,
              "width": 40,
              "title": "单号：",
              "textAlign": "right",
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle"
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 474,
              "top": 88.5,
              "height": 15,
              "width": 70,
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "field": "orderId",
              "textAlign": "right",
              "testData": "2020-06-19"
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 36,
              "top": 255,
              "height": 19.5,
              "width": 9
            },
            "printElementType": {
              "type": "vline"
            }
          },
          {
            "options": {
              "left": 36,
              "top": 255,
              "height": 9,
              "width": 525
            },
            "printElementType": {
              "type": "hline"
            }
          },
          {
            "options": {
              "left": 36,
              "top": 255,
              "height": 19.5,
              "width": 99,
              "title": "金额合计<大写>：",
              "fontSize": 10.5,
              "textAlign": "center",
              "textContentVerticalAlign": "middle"
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 135,
              "top": 255,
              "height": 19.5,
              "width": 216,
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "field": "totalChina",
              "testData": "陆仟元整"
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 36,
              "top": 110,
              "height": 145.5,
              "width": 525,
              "tableFooterRepeat": "page",
              "autoCompletion": true,
              "field": "table",
              "textAlign": "center",
              "fontSize": 11.25,
              "tableHeaderFontSize": 11.25,
              "tableBodyRowHeight": 18,
              "columns": [
                [{
                  "title": "业务类型",
                  "field": "name",
                  "width": 189.3326949860724,
                  "colspan": 1,
                  "rowspan": 1,
                  "checked": true,
                  "columnId": "name"
                }, {
                  "title": "数量",
                  "field": "num",
                  "width": 56.25250696378833,
                  "colspan": 1,
                  "rowspan": 1,
                  "checked": true,
                  "columnId": "num"
                }, {
                  "title": "单价",
                  "field": "price",
                  "width": 69.4147980501393,
                  "align": "center",
                  "colspan": 1,
                  "rowspan": 1,
                  "checked": true,
                  "columnId": "price"
                }, {
                  "title": "金额",
                  "field": "totalPrice",
                  "width": 79.40807799442896,
                  "colspan": 1,
                  "rowspan": 1,
                  "checked": true,
                  "columnId": "totalPrice"
                }, {
                  "title": "备注",
                  "field": "remark",
                  "width": 130.59192200557104,
                  "colspan": 1,
                  "rowspan": 1,
                  "checked": true,
                  "columnId": "remark"
                }]
              ]
            },
            "printElementType": {
              "title": "表格",
              "type": "table",
              "footerFormatter": function (options, rows, data, currentPageGridRowsData) {
                var total = 0
                var count = 0
                for (var i = 0; i < currentPageGridRowsData.length; i++) {
                  count = Number(currentPageGridRowsData[i].num) + count
                  // var price = parseFloat(currentPageGridRowsData[i].price).toFixed(2) * currentPageGridRowsData[i].num
                  // total = total + price
                  total = parseFloat(Number(currentPageGridRowsData[i].totalPrice) + Number(total)).toFixed(2)
                }
                return "<tr><td>合计</td><td>" + count + "</td><td></td><td>" + total + "</td><td></td></tr>"
              }
            }
          },
          {
            "options": {
              "left": 351,
              "top": 255,
              "height": 19.5,
              "width": 48,
              "title": "￥：",
              "letterSpacing": 10.5,
              "textAlign": "center",
              "textContentVerticalAlign": "middle"
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 399,
              "top": 255,
              "height": 19.5,
              "width": 162,
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "field": "countTotal",
              "testData": "6000"
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 560.4,
              "top": 255,
              "height": 20,
              "width": 9
            },
            "printElementType": {
              "type": "vline"
            }
          },
          {
            "options": {
              "left": 36,
              "top": 274.5,
              "height": 9,
              "width": 525
            },
            "printElementType": {
              "type": "hline"
            }
          },
          {
            "options": {
              "left": 36,
              "top": 279,
              "height": 18,
              "width": 33,
              "title": "户名：",
              "fontSize": 10.5,
              "lineHeight": 13.5,
              "textContentVerticalAlign": "middle",
              "right": 69.99375915527344,
              "bottom": 297.75,
              "vCenter": 53.49375915527344,
              "hCenter": 288.75
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 69,
              "top": 279,
              "height": 18,
              "width": 282,
              "field": "accountName",
              "testData": "黄浩杰",
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "lineHeight": 13.5,
              "right": 351.99375915527344,
              "bottom": 298.5,
              "vCenter": 210.99375915527344,
              "hCenter": 289.5
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 36,
              "top": 297,
              "height": 18,
              "width": 45,
              "title": "开户行：",
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "lineHeight": 13.5
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 80.5,
              "top": 297,
              "height": 18,
              "width": 270,
              "field": "bank",
              "testData": "中国工商银行 普宁中河支行",
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "lineHeight": 13.5
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 35.5,
              "top": 315,
              "height": 18,
              "width": 54,
              "title": "银行账号：",
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "lineHeight": 13.5,
              "right": 88.5,
              "bottom": 332.25,
              "vCenter": 61.5,
              "hCenter": 323.25
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 89.5,
              "top": 315,
              "height": 18,
              "width": 261,
              "field": "bankAccount",
              "testData": "6212252019001789122",
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "lineHeight": 13.5
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 35.5,
              "top": 333,
              "height": 18,
              "width": 75,
              "title": "售后服务号码：",
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle"
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 109,
              "top": 333,
              "height": 18,
              "width": 70.5,
              "field": "service",
              "testData": "13724322226",
              "fontSize": 10.5,
              "textAlign": "center",
              "textContentVerticalAlign": "middle"
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 178.5,
              "top": 333,
              "height": 18,
              "width": 43.5,
              "title": "（同微）",
              "fontSize": 10.5,
              "textAlign": "center",
              "textContentVerticalAlign": "middle"
            },
            "printElementType": {
              "type": "text"
            }
          },
          {
            "options": {
              "left": 351,
              "top": 333,
              "height": 18,
              "width": 114,
              "title": "收款单位（盖章）：",
              "color": "#cc5a5a",
              "fontSize": 10.5,
              "textContentVerticalAlign": "middle",
              "right": 465,
              "bottom": 350.25,
              "vCenter": 408,
              "hCenter": 341.25
            },
            "printElementType": {
              "title": "文本",
              "type": "text"
            }
          }
        ],
        "paperNumberLeft": 555,
        "paperNumberTop": 385,
        "paperNumberContinue": true,
        "backgroundColor": "#f1ebff",
        "overPrintOptions": {},
        "watermarkOptions": {}
      }
    ]
  }